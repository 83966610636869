import Image from "next/image";
import React from "react";

const FooterAppAds = () => {
  const title = "Sekarang sudah tersedia Aplikasi Rumahdewi";
  const description =
    "Maksimalkan eksposur iklan Anda dengan Aplikasi Rumahdewi, Pasang iklan Anda sekarang dan nikmati keuntungannya dengan Aplikasi Rumahdewi!";
  const webDownload =
    "https://play.google.com/store/apps/details?id=com.rumahdewiagent.app";
  return (
    // <div className="footer-bg-img flex-grow-1 position-sticky bottom-0">
    <>
      <div className="d-none d-md-block">
        <div className="position-relative">
          <div className="position-absolute bottom-0">
            <Image
              src="/assets/banner-footer.png"
              alt="background footer mobile"
              width={5709}
              height={972}
            />
          </div>

          <div className="container row align-items-end position-relative">
            <div className="col-6 position-relative" style={{ left: "12%" }}>
              <Image
                src="/assets/mockup-android.png"
                alt="mockup mobile"
                width={200}
                height={320}
              />
            </div>
            <div className="pb-5 col-12 col-sm-6 d-flex flex-column justify-content-between gap-3">
              <div>
                <h4 className="text-primary h6">{title}</h4>
                <small>{description}</small>
              </div>

              <a href={webDownload} className="cursor-pointer">
                <Image
                  src="/assets/gplay.png"
                  alt="google play logo"
                  width={108}
                  height={35}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="down-app-mobile-bg-img flex-grow-1 d-md-none">
        <div className="container py-4">
          <div className="row align-items-center">
            <div className="col-3">
              <Image
                src="/assets/mockup-android.png"
                alt="mockup mobile"
                width={250}
                height={450}
              />
            </div>
            <div className="col-9 d-flex flex-column justify-content-between gap-2">
              <div>
                <p className="text-primary h5">{title}</p>
                <p className="small-secondary-text">{description}</p>
              </div>

              <a href={webDownload} className="cursor-pointer">
                <Image
                  src="/assets/gplay.png"
                  alt="gplay logo"
                  width={78}
                  height={25}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterAppAds;
