import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function Footer(props) {
  const { isLanding } = props;
  return (
    <>
      <hr className="my-0" />
      <footer>
        <div className={`px-0 ${!isLanding ? "bg-white shadow-sm" : ""}`}>
          <div className="container">
            <div className="d-flex gap-4 align-items-center justify-content-between py-2">
              <Link href="/">
                <a>
                  <div className="d-none d-md-block">
                    <Image
                      src="/logo_rmd_blue.png"
                      alt="logo rumahdewi"
                      width="200px"
                      height="60px"
                      quality={100}
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <Image
                      src={"/logo_rmd_blue.png"}
                      alt="logo rumahdewi"
                      width="150px"
                      height="50px"
                      quality={100}
                    />
                  </div>
                </a>
              </Link>

              <div className="d-none d-sm-flex gap-3 footer-logo">
                <strong className="mb-0 align-self-center ">Follow us</strong>

                <Link
                  href={`https://www.facebook.com/rumahdewicom?mibextid=ZbWKwL`}
                >
                  <a target="_blank" className="footer-logo">
                    <i class="bi bi-facebook " />
                  </a>
                </Link>

                <Link href={`https://x.com/rumahdewionline`}>
                  <a target="_blank" className="footer-logo">
                    <i class="bi bi-twitter-x" />
                  </a>
                </Link>

                <Link
                  href={`https://www.instagram.com/rumahdewi.ig?igsh=MTVlYzRuN3M0cm90Yg==`}
                >
                  <a target="_blank" className="footer-logo">
                    <i class="bi bi-instagram" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
