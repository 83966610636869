import { gql } from "@apollo/client";

export const PACKAGE_FRAGMENT = gql`
  fragment Packages on PackageListsForUser {
    package_list {
      id
      name
      type
      package_feature {
        name
        type
        value
      }
      package_price {
        id
        package_state {
          id
          state_id
          state {
            state_name
          }
          package_price_id
          special_price
        }
        price
        duration_type
        allow_claim
        has_discount
        selling_info {
          price
          discount_type
          discount_value
        }
      }
    }
    package_prices {
      package_price {
        id
        package_state {
          id
          state_id
          state {
            state_name
          }
          package_price_id
          special_price
        }
        package_list {
          id
          name
          package_feature {
            name
            type
            value
          }
        }
        price
        duration_type
        allow_claim
        has_discount
        selling_info {
          price
          discount_type
          discount_value
        }
      }
      is_active
    }
  }
`;

export const PACKAGE_PRICES = gql`
  query rdGetPackagePrice($lat: String, $lng: String, $token: String) {
    package_lists_for_user(lat: $lat, lng: $lng, token: $token) {
      ...Packages
    }
  }
  ${PACKAGE_FRAGMENT}
`;

export const GET_STATE_USER = gql`
  query rdGetStateuser($lat: String!, $lng: String!) {
    state {
      state_by_lat_lng(lat: $lat, lng: $lng) {
        state_id
        state_name
      }
    }
  }
`;

export const PACKAGE_PRICE_DETAIL = gql`
  query rdGetPackagePriceDetail(
    $id: Int!
    $lat: String
    $lng: String
    $token: String
  ) {
    package_price(id: $id, lat: $lat, lng: $lng, token: $token) {
      id
      package_state {
        id
        state_id
        state {
          state_name
        }
        package_price_id
        special_price
      }
      package_list {
        id
        name
        package_feature {
          name
          type
          value
        }
      }
      price
      duration_type
      has_discount
      selling_info {
        price
        discount_type
        discount_value
      }
    }
  }
`;

export const PURCHASE_MEMBER = gql`
  mutation rdPurchaseMember($package_price_id: [ID!]!, $voucher_code: String) {
    money_member_transaction {
      create(package_price_id: $package_price_id, voucher_code: $voucher_code) {
        payment_link
      }
    }
  }
`;

export const CLAIM_PACKAGE = gql`
  mutation rdClaimPackage($package_price_id: ID!) {
    user_package {
      claim(package_price_id: $package_price_id) {
        ...Packages
      }
    }
  }
  ${PACKAGE_FRAGMENT}
`;
